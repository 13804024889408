import { FEATURE, ACTION } from '../../config/roles';

//menu atual
export function SidebarMenuOptions() {
    const user = JSON.parse(localStorage.getItem('@Auth:user'));

    return [
        {
            key: '1',
            name: 'Menu.Home',
            icon: 'HomeFilled',
            url: user && user.customHomePage ? '/home/custom' : '/home',
            newPage: false,
            subMenu: [],
            permission: [],
        },
        {
            key: '2',
            name: 'Menu.Formbuilder',
            icon: 'FormOutlined',
            url: '/formbuilder',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.FORMBUILDER,
                    actions: [
                        ACTION.EXPORT,
                        ACTION.DELETE,
                        ACTION.VIEW,
                        ACTION.CREATE,
                        ACTION.TRANSLATE,
                        ACTION.EDIT,
                        ACTION.APPROVER,
                    ],
                },
            ],
        },
        {
            key: '3',
            name: 'Menu.DocumentControl',
            icon: 'FolderOutlined',
            url: '/documentcontrol',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.DOCUMENTCONTROL,
                    actions: [
                        ACTION.DCCOMMON,
                        ACTION.DCCONFIDENTIAL,
                        ACTION.DCAPPROVER,
                        ACTION.DCREVISION,
                        ACTION.DCREDELEGATE,
                    ],
                },
            ],
        },
        {
            key: '4',
            name: 'Menu.Workflow',
            icon: 'BuildOutlined',
            url: '/workflow',
            newPage: false,
            subMenu: [],
            permission: [
                { feature: FEATURE.ACCESS, actions: [ACTION.WEBACCESS] },
            ],
        },
        {
            key: '5',
            name: 'PowerBI',
            icon: 'AreaChartOutlined',
            url: '/powerbi',
            newPage: false,
            subMenu: [],
            permission: [{ feature: FEATURE.DASHBOARD, actions: [ACTION.PBI] }],
        },
        {
            key: '5.1',
            name: 'Dashboard - BETA',
            icon: 'PieChartOutlined',
            url: '/dashboard',
            newPage: false,
            subMenu: [],
            permission: [{ feature: FEATURE.DASHBOARD, actions: [ACTION.FLOWY] }],
        },
        {
            key: '6',
            name: 'Divider',
            subMenu: [],
        },
        {
            key: '7',
            name: 'Menu.Tenant',
            icon: 'FaRegBuilding',
            url: '/tenant',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.TENANT,
                    actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE],
                },
            ],
        },
        {
            key: '8',
            name: 'Menu.Topic',
            icon: 'FaRegListAlt',
            url: '/topic',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.TOPIC,
                    actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE],
                },
            ],
        },
        {
            key: '9',
            name: 'Menu.User',
            icon: 'UsergroupAddOutlined',
            url: '/user',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.USER,
                    actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE],
                },
            ],
        },
        {
            key: '10',
            name: 'Menu.Location',
            icon: 'FaSearchLocation',
            url: '/location',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.LOCATION,
                    actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE],
                },
            ],
        },
        {
            key: '12',
            name: 'Menu.List',
            icon: 'FaRegListAlt',
            url: '/list',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.LIST,
                    actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE],
                },
            ],
        },
        {
            key: '13',
            name: 'Menu.TreeList',
            icon: 'CgListTree',
            url: '/treelist',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.TREELIST,
                    actions: [ACTION.VIEW, ACTION.TRANSLATE, ACTION.MANAGE],
                },
            ],
        },
        {
            key: "14",
            name: "OriginInformation",
            icon: "OriginInfo",
            url: "/origininformation",
            newPage: false,
            subMenu: [],
            permission: [{ feature: FEATURE.ORIGININFORMATION, actions: [ACTION.VIEW, ACTION.MANAGE] }]
        },
        {
            key: '15',
            name: 'Menu.Relatorios',
            icon: 'FaFolderOpen',
            url: '/reports',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.REPORTS,
                    actions: [ACTION.VIEW, ACTION.VIEWALL],
                },
            ],
        },
        {
            key: '17',
            name: 'Menu.Administration',
            icon: 'RiAdminLine',
            url: '/administration',
            newPage: false,
            subMenu: [],
            permission: [
                {
                    feature: FEATURE.ROLE,
                    actions: [ACTION.VIEW, ACTION.MANAGE],
                },
            ],
        },
        {
            key: '18',
            name: 'Divider',
            subMenu: [],
        },
        {
            key: '19',
            name: 'Wiki',
            icon: 'MenuHelp',
            url: '/wiki',
            newPage: false,
            subMenu: [],
            permission: [],
        }
    ];
}